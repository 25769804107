import React, { useContext, useEffect, useState } from "react";
import Classes from "./yourDashboard.module.scss";
import Overview from "../Overview";
import useStake from "../../contract/hooks/stake/useStake";
import { useAccount } from "wagmi";
import { ethers } from "ethers";
import useApprove from "../../contract/hooks/token/useApprove";
import { createStake, getStakeAmountByUser } from "../../api/services/stake";
import SocketContext from "../../context/socket";
import { CONTRACT_INFO } from "../../contract";
import { toast } from "react-toastify";
import { getUserStats } from "../../api/services/user";
import { Col, Row } from "antd";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import {
  calculatePercentageOfTwoValues,
  decimalnumbervalidator,
  extractErrorMessage,
  formatLargeNumber,
} from "../../helpers/common";
import useGetPayout from "../../contract/hooks/stake/useGetPayout";
import useGetBlnc from "../../contract/hooks/token/useGetBlnc";
import { useSelector } from "react-redux";
import Reward from "../Reward";
import useGetPlatformStats from "../../contract/hooks/stake/useGetPlatformInfo";
function YourDashboard() {
  const [amount, setAmount] = useState("");
  const [loadingMessage, setLoaderMessage] = useState(null);
  const [investmentComparison, setInvestmentComparison] = useState(null);
  const subtitles = [
    "Total Payout",
    "Payout from Treasury Pool",
    "Payout from OwnerShip Pool",
  ];
  const { address } = useAccount();
  const [loader, setLoader] = useState(false);
  const [stats, setStats] = useState(null);
  const socket = useContext(SocketContext);
  const user = useSelector((state) => state?.auth?.user);
  const {
    approve, // Renamed the function
    approveSentError,
    approveTxError,
    approveResp,
    isApproveCompleted,
    approveSentTx,
  } = useApprove();

  const { stake, stakeSentTx, stakeSentError, stakeResp, stakeTxError } =
    useStake();
  const {
    totalPayout,
    treasurePoolpayout,
    ownershipPoolPayout,
    totalStakedAmountByUser,
    refetchPayout,
  } = useGetPayout(address);
  const { investment: totalStakedAmount, refetchInvestment } =
    useGetPlatformStats();
  const { blnc, refetch } = useGetBlnc(address);
  useEffect(() => {
    if (approveSentTx) {
      setLoaderMessage("Transaction is in progress please wait");
    }
  }, [approveSentTx]);
  useEffect(() => {
    // let timeoutId
    if (isApproveCompleted) {
      setLoaderMessage("Please approve transaction from wallet");
      stake({
        args: [ethers.utils.parseEther(`${amount}`)],
        from: address,
      });
    }
  }, [isApproveCompleted]);
  useEffect(() => {
    const addStake = async () => {
      const res = await createStake({
        amount: Number(amount),
        txHash: stakeSentTx?.hash,
        type: "stack",
      });
    };
    if (stakeSentTx?.hash) {
      setLoaderMessage("Transaction is in progress please wait");
      addStake();
    }
  }, [stakeSentTx?.hash]);

  useEffect(() => {
    if (stakeResp) {
      try {
      } catch (err) {
        console.log(err, "error=>");
      }
    }
  }, [stakeResp]);
  useEffect(() => {
    const error =   stakeSentError || stakeTxError || approveTxError || approveSentError;
    if (error) {
      setLoader(false);
      toast.error(extractErrorMessage(error.message));
      setLoaderMessage(null);
    }
  }, [stakeSentError, stakeTxError, approveTxError, approveSentError]);
  useEffect(() => {
    const getStats = async () => {
      const res = await getUserStats();
      setStats(res?.data?.data);
    };
    // getStats();
  }, []);
  useEffect(() => {
    if (
      (totalStakedAmount !== null || totalStakedAmount >= 0) &&
      (totalStakedAmountByUser !== null || totalStakedAmountByUser >= 0)
    ) {
      setInvestmentComparison([
        { name: "Your Investment", value:formatLargeNumber(totalStakedAmountByUser) },
        { name: "Total Investment", value: formatLargeNumber(totalStakedAmount) },
      ]);
    }
  }, [totalStakedAmountByUser, totalStakedAmount]);
  useEffect(() => {
    if (socket && user?._id) {
      console.log("joining=>");
      const handleStake = () => {
        console.log("stake event received ===>");
        setLoader(false);
        toast.success("Amount staked successfully");
        refetch();
        refetchInvestment();
        refetchPayout();
        setAmount("");
      };
      socket.emit("join", `${user?._id}`);
      socket?.on("Stake", handleStake);

      return () => {
        socket?.off("Stake", handleStake);
        socket.emit("leave", `${user?._id}`);
      };
    }
  }, [socket]);
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    index,
    name,
    value,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const label = name;

    return (
      <g>
        <text
          x={x + 10}
          y={y - 6}
          fill="white"
          textAnchor="middle"
          dominantBaseline="central"
          fontSize="14"
          fontWeight="700"
          className="value-label"
        >
          {`${value} USDC`}
        </text>
        <text
          x={x + 10}
          y={y + 14 - 5}
          fill="white"
          textAnchor="middle"
          dominantBaseline="central"
          fontSize="12"
          fontWeight="300"
          className="name-label"
        >
          {label}
        </text>
      </g>
    );
  };

  return (
    <div className={Classes.dashboard}>
      <Overview
        subtitles={subtitles}
        userDashbaord
        stats={{
          title1: formatLargeNumber(totalPayout),
          title2: formatLargeNumber(treasurePoolpayout),
          title3: formatLargeNumber(ownershipPoolPayout),
        }}
      />

      <div className={Classes.card}>
        <Row gutter={[20, 20]} className="d-flex justify-content-center">
          <Col lg={18} md={24} sm={24} xs={24}>
            <div className={Classes.staked}>
              <div>
                {/* <h2 className={`${Classes.title} mb-3`}>
                  Total Invested Amount:{" "}
                  <span>{`${formatLargeNumber(totalStakedAmountByUser || 0)} USDC`}</span>
                </h2> */}
                
                <h2 className={`${Classes.title} mt-0 mb-3`}>
                  Available Balance: <span>{`${formatLargeNumber(blnc || 0)} USDC`}</span>
                </h2>
                <h2 className={`${Classes.title} mt-0 mb-3`}>
                  Ownership Pool: <span>{`${calculatePercentageOfTwoValues(totalStakedAmountByUser||0,totalStakedAmount)} %`}</span>
                </h2>
                <div>
                  <div className="label">Amount (USDC)</div>
                  <input
                    type="number"
                    className="baseInput mb-4 d-block"
                    placeholder="Enter the amount"
                    onChange={(e) => {
                      if (+e.target.value > blnc) {
                        return toast.error("Insuficient USDC Tokens");
                      }
                      setAmount(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      decimalnumbervalidator(e, 4);
                    }}
                    value={amount}
                  />
                </div>
                <button
                  className="btn"
                  disabled={loader || user?.isBanned || Number(amount) <= 0}
                  onClick={() => {
                    if (!address) {
                      return toast.error("Please connect wallet");
                    }
                    setLoader(true);
                    setLoaderMessage("Please approve transaction from wallet");
                    approve({
                      args: [
                        CONTRACT_INFO.stake.address,
                        ethers.utils.parseEther(`${amount}`),
                      ],
                      from: address,
                    });
                  }}
                >
                  {loader
                    ? loadingMessage
                    : user?.isBanned
                      ? "Please contact admin to enable your staking"
                      : "Invest"}
                </button>
              </div>
              {investmentComparison && (
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart>
                    <defs>
                      <linearGradient
                        id="colorUserIncome"
                        x1="0"
                        y1="0"
                        x2="1"
                        y2="1"
                      >
                        <stop offset="0" stopColor="#FEC036" />
                        <stop offset="100%" stopColor="#C37A00" />
                      </linearGradient>
                      <linearGradient
                        id="colorTotalIncome"
                        x1="0"
                        y1="0"
                        x2="1"
                        y2="1"
                      >
                        <stop offset="0%" stopColor="#B31017" />
                        <stop offset="100%" stopColor="#A21016" />
                      </linearGradient>
                    </defs>
                    <Pie
                      data={investmentComparison}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={120}
                      fill="#8884d8"
                      dataKey="value"
                      strokeWidth={0}
                    >
                      <Cell key={`cell-0`} fill="url(#colorUserIncome)" />
                      <Cell key={`cell-1`} fill="url(#colorTotalIncome)" />
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              )}
            </div>
          </Col>
          <Col lg={6} md={24} sm={24} xs={24}>
            <Reward />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default YourDashboard;
